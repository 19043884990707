import { registerLocaleData } from '@angular/common';
import esMX from '@angular/common/locales/es-MX';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { es as esES } from 'date-fns/locale';
import { FormConfig, provideNzConfig } from 'ng-zorro-antd/core/config';
import { NZ_DATE_LOCALE, NZ_I18N, es_ES } from 'ng-zorro-antd/i18n';

registerLocaleData(esMX);

const defaultAutoTips = {
  required: 'Este campo es obligatorio.',
  minlength: 'El campo es muy corto.',
  maxlength: 'El campo es muy largo.',
};

const nzAutoTips: FormConfig['nzAutoTips'] = {
  es: defaultAutoTips,
  en: {
    required: 'This field is required.',
    minlength: 'Field is too short.',
    maxlength: 'Field is too long.',
  },
  default: defaultAutoTips,
};

@NgModule({
  imports: [TranslocoModule],
  providers: [
    { provide: NZ_I18N, useValue: es_ES },
    { provide: NZ_DATE_LOCALE, useValue: esES },
    provideNzConfig({ form: { nzAutoTips } }),
  ],
  exports: [TranslocoModule],
})
export class I18nModule {}
